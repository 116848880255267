import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import fr from "./locales/fr.json";
import en from "./locales/en.json";
import {
  fetchSales,
  fetchSalesByWeek,
  fetchSalesPreview,
} from "./services/api";
import Ecards from "./components/cards";
import { removeDoubleInfos } from "./utils/info";

import "./App.css";

const init = () => {
  const params = new URLSearchParams(document.location.search);
  return {
    token: params.get("token") || undefined,
    noVEL: params.get("noVEL") || undefined,
    no4807: params.get("no4807") || undefined,
    resort: params.get("resort") || undefined,
    email: params.get("email") || undefined,
    week: params.get("week") || undefined,
    season: params.get("season") || undefined,
    forceLang: params.get("lang") || undefined,
    preview: params.get("preview") || undefined,
    noprint: params.get("noprint") || undefined,
    header: parseInt(params.get("header")),
  };
};

const App = () => {
  const {
    token,
    noVEL,
    no4807,
    resort,
    email,
    week,
    season,
    forceLang,
    header,
    preview,
    noprint,
  } = init();

  const [cards, setCards] = useState([]);
  const [school, setSchool] = useState({});
  const [lang, setLang] = useState(forceLang || "fr");
  const [error, setError] = useState(false);

  useEffect(() => {
    const _fetchInscriptions = async () => {
      try {
        const byNums = noVEL !== undefined || no4807 !== undefined;
        const { sales, school } = preview
          ? await fetchSalesPreview(token, resort)
          : byNums
          ? await fetchSales(token, noVEL, resort, no4807, forceLang)
          : await fetchSalesByWeek(
              token,
              resort,
              email,
              week,
              season,
              forceLang
            );
        const cleanSales = removeDoubleInfos(sales);
        setCards(cleanSales);
        setSchool(school);
        if (!forceLang && sales.length > 0 && sales[0].client.lang) {
          setLang(sales[0].client.lang === "fr" ? "fr" : "en");
        }
      } catch (e) {
        setError(e.code === 403 ? "token" : "nodata");
      }
    };
    _fetchInscriptions();
  }, [
    token,
    noVEL,
    no4807,
    resort,
    email,
    week,
    season,
    forceLang,
    preview,
    noprint,
  ]);

  const messages = lang === "fr" ? fr : en;
  return (
    <IntlProvider locale={lang} messages={messages}>
      <Ecards
        cards={cards}
        school={school}
        error={error}
        lang={lang}
        header={header}
        noprint={noprint}
      />
    </IntlProvider>
  );
};

export default App;
