import React from "react";
import classnames from "classnames";
import { withConfiguration } from "../services/config";
import css from "./card.module.css";
import { useIntl } from "react-intl";
import PrintIcon from "../icons/print";

const HOST = withConfiguration((config) => config["widget-api"]);
const buildImgUrl = (img) => `${HOST}/images/esf${img.replace("/img", "")}`;

const Card = ({ school, card, selected, onMouseDown, onMouseUp, noprint }) => {
  const intl = useIntl();

  const levels = card.inscription.levels.filter(
    (level) => level.img !== undefined
  );

  const studentsNb = card.students.length;
  const print = () => {
    window.print();
  };
  return (
    <div
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      className={classnames(css.card, {
        [css.selected]: selected,
      })}
    >
      <div className={css.layout}>
        <div className={css.head}>
          <div className={css.esf}>
            <img className={css.esfLogo} src="/esf.svg" alt="esf" />
            <span className={css.esfName}>{school.name?.toLowerCase()}</span>
            {!noprint && selected && (
              <div className={css.printLogoBox} onClick={print}>
                <PrintIcon className={css.printLogo} alt="print" />
              </div>
            )}
          </div>
        </div>
        <div className={css.content}>
          {levels && levels.length > 0 && (
            <div className={css.levelOuter}>
              <img
                className={css.level}
                src={buildImgUrl(levels[0].img)}
                alt={levels[0].label}
              />
            </div>
          )}
          <div className={css.lesson}>
            <span className={css.title}>
              {card.inscription.labels.map((label, index) => (
                <div key={index}>{label}</div>
              ))}
            </span>
            <span className={css.fullname}>
              {studentsNb > 1
                ? intl.formatMessage({ id: `student` }, { count: studentsNb })
                : `${card.students[0].firstname} ${card.students[0].lastname}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
