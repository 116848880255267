import React from "react";
import css from "./card-info-line.module.css";
import PhoneIcon from "../icons/phone";
import EmailIcon from "../icons/email";
import { useIntl } from "react-intl";

const infoValue = (value) => {
  const array = Array.isArray(value);
  return !array ? (
    value === undefined ||
    value === null ||
    !value.toString().startsWith("https") ? (
      value
    ) : (
      <div className={css.infoValueLink}>{value}</div>
    )
  ) : (
    value.map((v, i) => <div key={i}>{v}</div>)
  );
};

const CardInfoLine = ({ label, value, link, onClick, phone, email }) => {
  const intl = useIntl();
  return (
    <div className={css.info}>
      <div className={css.infoTitle}>{intl.messages[label] || label}</div>
      <div className={css.infoValue}>{infoValue(value)}</div>
      {(link || onClick) && (
        <a className={css.infoLink} href={link} onClick={onClick}>
          {intl.messages.detail}
        </a>
      )}
      {(phone || email) && (
        <div className={css.infoLink}>
          {phone && (
            <a className={css.infoLinkButton} href={`tel:${phone}`}>
              <span className={css.infoLinkButtonText}>{phone}</span>
              <PhoneIcon className={css.img} />
            </a>
          )}
          {email && (
            <a className={css.infoLinkButton} href={`mailto:${email}`}>
              <span className={css.infoLinkButtonText}>{email}</span>
              <EmailIcon className={css.img} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default CardInfoLine;
