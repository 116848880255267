import * as React from "react";

const EmailIcon = (props) => {
  return (
    <svg viewBox="0 0 24 24" width="18" height="18">
      <path d="M11.585 5.773c1.832 0 3.559.812 4.824 2.08v.004c0-.61.41-1.07.977-1.07h.144c.895 0 1.074.843 1.074 1.109l.004 9.466c-.063.62.64.94 1.032.542 1.518-1.56 3.339-8.03-.946-11.776-3.996-3.497-9.359-2.92-12.21-.956-3.031 2.091-4.968 6.712-3.087 11.054 2.055 4.734 7.93 6.146 11.426 4.738 1.77-.714 2.586 1.673.747 2.454-2.773 1.182-10.5 1.06-14.109-5.182C-.977 14.019-.849 6.6 5.621 2.757 10.566-.185 17.09.63 21.023 4.732c4.11 4.292 3.872 12.323-.14 15.444-1.818 1.42-4.516.04-4.497-2.03l-.02-.673c-1.265 1.251-2.949 1.986-4.781 1.986-3.625 0-6.816-3.189-6.816-6.806 0-3.656 3.191-6.878 6.816-6.878v-.002zm4.559 6.619c-.137-2.65-2.106-4.246-4.485-4.246h-.09c-2.742 0-4.265 2.158-4.265 4.604 0 2.742 1.84 4.475 4.254 4.475 2.695 0 4.464-1.97 4.593-4.3l-.007-.533z" />
    </svg>
  );
};

export default EmailIcon;
