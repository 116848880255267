import * as React from "react";

const PhoneIcon = (props) => {
  return (
    <svg viewBox="0 0 24 24" width="18" height="18">
      <path d="M8.488 10.243a14.075 14.075 0 005.27 5.269l1.178-1.65a1.333 1.333 0 011.725-.395 15.23 15.23 0 006.111 1.818A1.333 1.333 0 0124 16.615v5.949c0 .684-.517 1.257-1.197 1.327a20.68 20.68 0 01-2.136.109C9.253 24 0 14.747 0 3.333c0-.717.036-1.429.11-2.136C.18.517.751 0 1.435 0h5.95C8.08 0 8.66.535 8.714 1.228a15.23 15.23 0 001.818 6.11 1.333 1.333 0 01-.394 1.726l-1.651 1.179zm-3.363-.876l2.534-1.81a17.88 17.88 0 01-1.463-4.89H2.68c-.008.221-.012.444-.012.666-.001 9.942 8.057 18 17.999 18 .222 0 .445-.004.666-.013v-3.516a17.88 17.88 0 01-4.89-1.463l-1.81 2.534a16.59 16.59 0 01-2.117-1l-.077-.044a16.741 16.741 0 01-6.27-6.27l-.044-.077a16.59 16.59 0 01-1-2.117z" />
    </svg>
  );
};

export default PhoneIcon;
