import React from "react";
import css from "./marker.module.css";

const Marker = ({ letter, size = 48 }) => {
  return (
    <svg
      viewBox="0 0 26 34"
      className={css.img}
      style={{ width: size, height: size }}
    >
      <path
        d="M11.77 32.35C2.69 19.2 1 17.84 1 13a12 12 0 1 1 24 0c0 4.84-1.69 6.19-10.77 19.35a1.5 1.5 0 0 1-2.46 0Z"
        stroke="#FFF"
      />
      {letter && (
        <text x={8.3} y={18} fontFamily="sans-serif" fontSize="14" fill="#fff">
          {letter}
        </text>
      )}
    </svg>
  );
};

export default Marker;
