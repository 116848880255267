import React, { useRef } from "react";
import classnames from "classnames";
import css from "./card-info.module.css";
import Content from "./content";
import CardInfoLine from "./card-info-line";
import { buildInfo, buildSchoolInfo } from "../utils/info";
import { useIntl } from "react-intl";

const CardInfo = ({ school, card, selected, lang }) => {
  const { inscription, contents } = card;
  const intl = useIntl();
  const mapRef = useRef(null);

  const schoolInfo = buildSchoolInfo(school);
  const infos = buildInfo(intl, lang, card, mapRef);

  return (
    <section
      className={classnames(css.card, {
        [css.selected]: selected,
      })}
    >
      <div className={css.header}>
        <span className={css.headerTitle}>
          {inscription.labels.map((label, index) => (
            <div key={index}>{label}</div>
          ))}
        </span>
        <hr className={css.headerSeparator} />
      </div>
      {infos.map(
        (info, index) => info.value && <CardInfoLine {...info} key={index} />
      )}
      <div className={css.esf}>
        <img className={css.esfLogo} src="/esf.svg" alt="esf" />
        <span className={css.esfName}>{school.name?.toLowerCase()}</span>
      </div>
      <div className={css.subHeader}>{intl.messages.esfsubtitle}</div>
      {schoolInfo.map((info, index) => (
        <CardInfoLine {...info} key={index} />
      ))}
      {contents.map((content, index) => (
        <Content
          {...content}
          key={index}
          index={index}
          anchorRef={content.code === "map" ? mapRef : undefined}
          contentProps={{ meetingPoints: inscription.meetingPoints }}
        />
      ))}
    </section>
  );
};

export default CardInfo;
