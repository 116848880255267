import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import Card from "./card";
import Dot from "./dot";
import CardInfo from "./card-info";

import css from "./cards.module.css";

const CARD_WIDTH = 290;
const PIVOT = 75;

const hasParents = (target, className) => {
  if (target === null || target.classList === undefined) {
    return null;
  }
  if (target.classList.contains(className)) {
    return target;
  }
  if (target !== document.body) {
    return hasParents(target.parentNode, className);
  }
};

const Ecards = ({ cards, school, error, lang, header, noprint }) => {
  const [current, setCurrent] = useState(0);
  const [width, setWidth] = useState(320);
  const cardsRef = useRef();
  const infosRef = useRef();
  const mouseDownAt = useRef();
  const intl = useIntl();

  const [props, set] = useSpring(() => ({
    x: -current * CARD_WIDTH,
    config: { mass: 1, tension: 500, friction: 39 },
  }));

  const bind = useDrag(
    ({ event, down, distance, movement: [mx], cancel }) => {
      if (hasParents(event.target, "maps")) {
        cancel();
      }
      const position = -current * CARD_WIDTH;
      const dx = position + mx;
      if (down && distance >= PIVOT) {
        const targetWithDecimal = -(dx / CARD_WIDTH);
        const target =
          mx < 0 ? Math.ceil(targetWithDecimal) : Math.floor(targetWithDecimal);

        const clamped = Math.min(Math.max(target, 0), cards.length - 1);
        setCurrent(clamped);
        cancel();
      }
      set({ x: down ? dx : position });
    },
    {
      axis: "x",
      rubberband: 0.25,
      bounds: {
        left: (cards.length - current - 1) * -CARD_WIDTH,
        right: current * CARD_WIDTH,
      },
    }
  );

  const cardChanged = (index) => () => {
    setCurrent(index);
    set({ x: -index * CARD_WIDTH });
  };
  const mouseDown = ({ clientX }) => {
    mouseDownAt.current = clientX;
  };
  const mouseUp =
    (index) =>
    ({ clientX }) => {
      const d = Math.abs(clientX - mouseDownAt.current);
      if (d < 2) cardChanged(index)();
    };

  const translateCards = (x) => {
    return `translate(${x}px, 0)`;
  };
  const translateInfos = (value) => {
    return translateCards((value / CARD_WIDTH) * width);
  };

  const setSizes = () => {
    setWidth(infosRef?.current?.offsetWidth);
  };

  const scrollToTop = () => {
    const content = document.querySelector(`.${css.cardsSelector}`);
    if (content) {
      content.scrollTo(0, 10000);
      requestAnimationFrame(() =>
        requestAnimationFrame(() => {
          content.scrollTo(0, 0);
        })
      );
    }
  };

  useEffect(() => {
    setSizes();
    const message = (event) => {
      const action = event.data ? event.data.action : undefined;
      if (action === "print") window.print();
    };
    window.addEventListener("resize", setSizes);
    window.addEventListener("beforeprint", scrollToTop);
    window.addEventListener("message", message);

    return () => {
      window.removeEventListener("resize", setSizes);
      window.removeEventListener("beforeprint", scrollToTop);
      window.removeEventListener("message", message);
    };
  });

  return error ? (
    <div className={css.cardsErrors}>
      {!isNaN(header) && (
        <div className={css.cardsHeader} style={{ height: 200 + header }}>
          <div className={css.cardsHeaderOverlay}></div>
        </div>
      )}
      <div
        className={css.cardsError}
        style={
          isNaN(header) ? undefined : { top: 60 + header, marginTop: -140 }
        }
      >
        <img className={css.cardsErrorLogo} src="/esf.svg" alt="esf" />
        <div>{intl.messages.error[error]}</div>
        <div className={css.cardsErrorMyesf}>
          <div className={css.cardsErrorMyesfTitle}>
            {intl.messages.error.myesf1}
          </div>
          <div>{intl.messages.error.myesf2}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`cards-selector ${css.cardsSelector}`}>
      {!isNaN(header) && (
        <div className={css.cardsHeader} style={{ height: 200 + header }}>
          <div className={css.cardsHeaderOverlay}></div>
        </div>
      )}
      <nav
        className={css.cards}
        style={isNaN(header) ? undefined : { top: 60 + header }}
        ref={cardsRef}
        {...bind()}
      >
        <animated.div
          className={css.cards__inner}
          style={{ transform: props.x.interpolate(translateCards) }}
        >
          {cards.map((card, index) => (
            <Card
              key={index}
              school={school}
              card={card}
              onMouseDown={mouseDown}
              onMouseUp={mouseUp(index)}
              selected={index === current}
              noprint={noprint}
            />
          ))}
        </animated.div>
      </nav>
      <nav className={css.cardsDots}>
        {cards?.length > 1 &&
          cards.map((_, index) => (
            <Dot
              key={index}
              onClick={cardChanged(index)}
              selected={index === current}
            />
          ))}
      </nav>
      <div className={css.info} {...bind()}>
        <div className={css.info__inner}>
          <animated.main
            ref={infosRef}
            className={css.cardInfos}
            style={{ transform: props.x.interpolate(translateInfos) }}
          >
            {cards.map((card, index) => (
              <CardInfo
                key={index}
                school={school}
                card={card}
                selected={index === current}
                lang={lang}
              />
            ))}
          </animated.main>
        </div>
      </div>
    </div>
  );
};

export default Ecards;
