import React from "react";
import Map from "pigeon-maps";
import Overlay from "pigeon-overlay";
import css from "./card-info.module.css";
import Marker from "../icons/marker";

const osmProvider = (x, y, z) => {
  const s = String.fromCharCode(97 + ((x + y + z) % 3));
  return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
};

const Copyright = () => (
  <a
    tabIndex="-1"
    target="copyright"
    href="https://www.openstreetmap.org/copyright"
    className={css.attribution}
  >
    © OpenStreetMap contributors
  </a>
);

const contents = {
  map: ({ meetingPoints }) => {
    return (
      <div className={`maps ${css.maps}`}>
        <Map
          twoFingerDrag
          provider={osmProvider}
          center={[meetingPoints[0].lat, meetingPoints[0].long]}
          defaultZoom={16}
          attributionPrefix={false}
          attribution={<Copyright />}
          boxClassname={css.mapWinter}
        >
          {meetingPoints.map((point, index) => (
            <Overlay
              key={index}
              anchor={[point.lat, point.long]}
              offset={[24, 48]}
              className={css.imgContainer}
              payload={1}
            >
              {meetingPoints.length > 1 ? (
                <Marker letter={point.letter} />
              ) : (
                <Marker />
              )}
            </Overlay>
          ))}
        </Map>
      </div>
    );
  },
};

const ContentOdd = ({ code, contentProps }) => {
  const Content = contents[code];
  return <Content {...contentProps} />;
};

export default ContentOdd;
