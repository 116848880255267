import { get, post } from "./fetcher";
import { withConfiguration } from "./config";

const api = "my-esf-api";

export const fetchSales = async (token, noVEL, resort, no4807, lang) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config[api]}/api/ecards`;
    return get(endpoint, { token, noVEL, resort, no4807, lang });
  });
};

export const fetchSalesByWeek = async (
  token,
  resort,
  email,
  week,
  season,
  lang
) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config[api]}/api/ecards/by-week`;
    return post(endpoint, { token, resort, email, week, season, lang });
  });
};

export const fetchSalesPreview = async (token, resort) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config[api]}/api/ecards/preview`;
    return post(endpoint, { token, resort });
  });
};
