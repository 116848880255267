import React from "react";
import { useIntl } from "react-intl";
import css from "./card-info.module.css";
import ContentOdd from "./content-odd";

const Content = ({ type, title, content }) =>
  type === "IMG" ? (
    <img className={css.image} src={content} alt={title} />
  ) : (
    <div dangerouslySetInnerHTML={{ __html: content }}></div>
  );

const Contents = ({
  code,
  type,
  odd,
  contents,
  content,
  title,
  displayTitle,
  index,
  contentProps,
  anchorRef,
}) => {
  const intl = useIntl();

  const display = Boolean(displayTitle || odd);
  const contentList = contents || [{ content, type, odd }];

  return contentList.some((c) => c.content || c.odd) ? (
    <div key={code} className={css.header} ref={anchorRef}>
      {display && (
        <span className={css.headerTitle}>{title || intl.messages[code]}</span>
      )}
      {(display || (!display && index !== 0)) && (
        <hr className={css.headerSeparator} />
      )}
      {contentList.map((content, index) =>
        content.odd ? (
          <ContentOdd
            {...content}
            code={code}
            contentProps={contentProps}
            key={index}
          />
        ) : (
          <Content {...content} code={code} key={index} />
        )
      )}
    </div>
  ) : (
    ""
  );
};

export default Contents;
