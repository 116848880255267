import React from "react";
import Marker from "../icons/marker";
import { formatDateText } from "../utils/format";

export const removeDoubleInfos = (cards) =>
  cards.map(({ inscriptions, inscription, schedules, ...card }) => {
    const inscriptionList = inscriptions || [inscription];

    const values = inscriptionList.reduce(
      (acc, insc) => ({
        activities: removeDoubleInfo(acc.activities, insc.specialite_label),
        instructors: removeDoubleInfo(acc.instructors, insc.instructor_name),
        labels: removeDoubleInfo(acc.labels, insc.label),
        classeAccueil: removeDoubleInfo(acc.classeAccueil, insc.classeAccueil),
      }),
      { activities: [], instructors: [], labels: [], classeAccueil: [] }
    );
    return {
      ...card,
      schedules: regroupSchedules(schedules),
      inscription: {
        ...values,
        levels: regroupLevels(inscriptionList),
        meetingPoints: regroupMeetingPoints(inscriptionList),
      },
    };
  });

const removeDoubleInfo = (array, info) =>
  !info || array.includes(info) ? array : [...array, info];

const regroupSchedules = (schedules) =>
  schedules
    .reduce((acc, schedule) => {
      const existsSchedule = acc.find(
        ({ dateFrom, dateTo }) =>
          dateFrom === schedule.dateFrom && dateTo === schedule.dateTo
      );
      return existsSchedule
        ? [
            ...acc.filter(
              (s) =>
                s.dateFrom !== existsSchedule.dateFrom ||
                s.dateTo !== existsSchedule.dateTo
            ),
            {
              ...existsSchedule,
              times: [...existsSchedule.times, ...schedule.times],
            },
          ]
        : [...acc, schedule];
    }, [])
    .map((schedule) => {
      schedule.times.sort(sortTimes);
      schedule.times = regroupTimes(schedule.times);
      return schedule;
    });

const sortTimes = ({ time_from: from1 }, { time_from: from2 }) => {
  const [hour1, min1] = from1.split("H").map((s) => parseInt(s));
  const [hour2, min2] = from2.split("H").map((s) => parseInt(s));
  return hour1 === hour2 ? (min1 > min2 ? 1 : -1) : hour1 > hour2 ? 1 : -1;
};

const regroupTimes = (times) =>
  times.reduce((acc, time) => {
    const last = acc.pop();
    return last !== undefined
      ? last.time_to === time.time_from
        ? [...acc, { time_from: last.time_from, time_to: time.time_to }]
        : [...acc, last, time]
      : [time];
  }, []);

const regroupLevels = (inscriptions) =>
  inscriptions.reduce((acc, { level_label: label, level_img: img }) => {
    return acc.some((lvl) => lvl.label === label)
      ? acc
      : [...acc, { label, img }];
  }, []);

const regroupMeetingPoints = (inscriptions) => {
  let charCode = 65;
  return inscriptions.reduce(
    (
      acc,
      {
        label,
        meeting_point_lat: lat,
        meeting_point_long: long,
        meeting_point_code: code,
        meeting_point_label: pointLabel,
      }
    ) => {
      const foundPoint = acc.find((point) => point.code === code);
      if (foundPoint) {
        return [
          ...acc.filter((p) => p.code !== code),
          { ...foundPoint, label: `${foundPoint.label} + ${label}` },
        ];
      } else {
        const letter = String.fromCharCode(charCode);
        charCode++;
        return [...acc, { lat, long, code, pointLabel, letter, label }];
      }
    },
    []
  );
};

const scrollToRef = (ref) => () => {
  if (ref.current) {
    document
      .querySelector(".cards-selector")
      .scrollTo(0, ref.current.offsetTop + 125);
  }
};

const wrapInArray = (elem) => (elem ? [elem] : []);

export const buildInfo = (
  intl,
  lang,
  { vente, students, schedules, inscription },
  mapRef
) => {
  const { meetingPoints, activities, levels, instructors, classeAccueil } =
    inscription;
  return [
    { label: "no4807", value: vente.no4807s || vente.no4807 },
    ...wrapInArray(vente.noMSEM && { label: "noMsem", value: vente.noMSEM }),
    ...wrapInArray(vente.noVEL && { label: "noVel", value: vente.noVEL }),
    ...wrapInArray(
      students.length > 1 && {
        label: "students",
        value: students.map(
          (student) => `${student.firstname} ${student.lastname}`
        ),
      }
    ),
    {
      label: "schedule",
      value: schedules.reduce(
        (acc, schedule) => [
          ...acc,
          `${intl.messages.from} ${formatDateText(schedule.dateFrom, lang)} ${
            intl.messages.to
          } ${formatDateText(schedule.dateTo, lang)}`,
          schedule.times
            .map(
              (time) =>
                `${intl.messages.from_time} ${time.time_from} ${intl.messages.to_time} ${time.time_to}`
            )
            .join(","),
        ],
        []
      ),
    },
    {
      label: "meeting",
      value:
        meetingPoints.length > 1
          ? meetingPoints.flatMap((point) => [
              <div style={{ fontWeight: 700, fontSize: 11 }}>
                {point.label}
              </div>,
              <div>
                <Marker letter={point.letter} size={20} />
                {point.pointLabel}
              </div>,
            ])
          : meetingPoints[0].pointLabel,
      onClick: scrollToRef(mapRef),
    },
    { label: "specialite", value: activities },
    ...wrapInArray(
      classeAccueil && {
        label: "classe_accueil_label",
        value: intl.messages.classe_accueil[classeAccueil],
      }
    ),
    { label: "level", value: levels.map((level) => level.label) },
    ...wrapInArray(
      instructors.length > 0 && {
        label: "instructor",
        value: instructors,
      }
    ),
  ];
};

export const buildSchoolInfo = ({ phone, email, site }) => [
  { label: "office", phone, email },
  { label: "site", value: site },
];

export const validGeolocalisation = (lat, long) =>
  lat && lat !== 0 && long && long !== 0;
